<template>
    <!-- 第一个页面：控制台首页-仪表盘 -->
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                           
                        </div>
                    </div>
                </div><!-- /End Row -->


                <div class="h-[calc(100vh-130px)] overflow-y-scroll">
                    <div v-for="item in mysql.powers" :key="item.team_id" class="relative overflow-x-auto   p-2 text-left text-ms">

                        <input :id="'teamID-'+item.team_id"   @click="butTeam(item)" v-model="item.checkbox" type="checkbox"  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ">
                        <label :for="'teamID-'+item.team_id">{{ item.team_id }} - {{ item.title }}</label>
                        
                        <div v-if="item.powers && item.powers.length > 0" class="px-10">
                            <div v-for="item2 in item.powers" :key="item2.power_id">
                                <input :id="'powerID-'+item2.power_id" @click="butPower(item2)"  v-model="item2.checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ">
                                <label :for="'powerID-'+item2.power_id" >{{ item2.power_id }} - {{ item2.title }} </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button @click="butPost" id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                            保存数据
                        </button>
                        <a @click="$router.go(-2);" id="states-button"  class="ms-4 flex-shrink-0 z-10 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                            取消编辑
                        </a>
                    </div>
                </div>

            </div>

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div><!-- /End Main -->
    </div>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { layer } from "vue3-layer"
import SideSar from "../../components/SideSar.vue"
import MainHeader from "../../components/MainHeader.vue"
import MainFooter from "../../components/MainFooter.vue"

// 通过 @ 相对路径加载自定义的 可重复应用的 JS 函数
import getPositionPowers from "@/assets/js/getPositionPowers"

import { toRaw } from '@vue/reactivity'


export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer
    },
    setup() {
        const route = useRoute()
        const position_id = route.query.positionId
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        
        return { position_id }
    },
    data() {
        return {
            lang: null,
            powers: null,
            own: null,
            mysql: {}
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    
    mounted() {
        
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang

        this.getData()
        return {}
    },
    // 
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        getData()
        {
            const mPowers = getPositionPowers(this.lang, localStorage.getItem('token'), this.position_id)
            // 将异步获取的数据导入 VM 数据
            mPowers.data.then(res =>{
                const list = toRaw(res)
                // let oJson = JSON.parse(JSON.stringify(res))
                // this.mysql = oJson.data
                // this.powers = list.data.powers
                // this.own =  list.data.own
                this.mysql = list.data

                this.mysql.own.forEach((e) => {
                    // 记录下，这个权组下，有多少个权限。
                    let counter = 0
                    this.mysql.powers.forEach((element) => {
                        let num = element.powers.length
                        element.powers.forEach((ps) => {
                            if (e.power_id === ps.power_id) {
                                ps.checkbox = true
                            }
                            element.powers.forEach((ps) => {
                                if( ps.checkbox ) counter += 1
                            })
                            // 如果
                            if (0<counter) element.checkbox = true
                            else element.checkbox = false
                        })
                        
                    })
                    
                })
            })
        },
        butPower(power)
        {
            power.checkbox = !power.checkbox
            // 记录下，这个权组下，有多少个权限。
            let counter = 0
            this.mysql.powers.forEach((element) => {
                if (power.team_id === element.team_id) {
                    
                    let num = element.powers.length
                    element.powers.forEach((ps) => {
                        if( ps.checkbox ) counter += 1
                    })
                    // 如果
                    if (0<counter) element.checkbox = true
                    else element.checkbox = false
                }
            })
        },
        butTeam(team) 
        {
            team.checkbox = !team.checkbox
            // console.log( team )
            // 遍历这个权组下，所有的权限，都要选中，或者都要不选中。
            // console.log( this.mysql.powers )
            this.mysql.powers.forEach((element) => {
                if (team.team_id === element.team_id) {
                    // 把下属的所有权限，都做与一样的 checkBox 状态
                    element.powers.forEach((power) => {
                        power.checkbox = team.checkbox
                    })
                }
            })
        },
        butPost()
        {
            // 提交职务的权限配置信息
            // position_id
            // power_id
            // console.log( this.position_id )
            let powers = []
            this.mysql.powers.forEach((element) => {
                element.powers.forEach((ps) => {
                    if( ps.checkbox ) powers.push(ps.power_id)
                })
            })
            // 这是新绑定的权限
            console.log( powers )
            // 从已经存有的权限中，分解出，哪些是增加的，哪些是要删除的。
            axios.post(
                '/api/x/set/position/powers', 
                {
                    position_id: this.position_id,
                    power: powers
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            // .then(function (response) {
            .then((response) =>  {
                // console.log( response.data )
                if (0===response.data.code) {
                    layer.msg('成功保存授权')
                //     localStorage.setItem('token', response.data.data.token)
                //     router.push({
                //         path: '/user',
                //         query: {
                //             msg: 'reg account'
                //         }
                //     })
                } else {
                    layer.msg('保存授权异常')
                //     // 先将表单的 Name 转成首字母大写
                //     const word = response.data.data.id
                //     const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                //     const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                //     this[tip] = response.data.data.msg
                //     this[capitalized] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        }
    },
    

}
</script>

<style>

</style>